
import React from "react"
import { Link } from "gatsby"

const CtaSection = ({ title, link, linkHref, linkTitle }) => {
    return (
        <div id="offer" className="gray-bg">
            <div className="section-content t-left big-text">
                {title ? (<h3 className="t-left f-large normal">{title}</h3>) : null}
                <div className="separator-small"></div>
                {link ? (
                    <p className="t-left f-normal normal">
                        <Link 
                            title={linkTitle ? linkTitle : ''} 
                            to={linkHref ? linkHref : '#'} 
                            className="underline"
                        >{link}</Link>
                    </p>
                ) : null}
            </div>
        </div>
    );
}

export default CtaSection;
