import React, { useRef, useEffect } from "react";
import { navigate } from "gatsby";

const HeroSection = ({ title = '', subtitle = '' }) => {
    const divRef = useRef();

    useEffect(() => {
        if (divRef && divRef.current) {
            window.$(divRef.current).find('a').on('click', function(e) {
                e.preventDefault();
                navigate(window.$(this).attr('href'));
            });
        }

        return () => {
            window.$(divRef.current).find('a').off('click');
        }
    }, []);

    return  (
        <div id="header" className="for-index">
            <div className="section-content" ref={divRef}>
                <div className="big-text">
                    <h2 className="t-left f-extra-expanded light" dangerouslySetInnerHTML={{ __html: title }} />
                    <div className="separator-small"></div>
                    <h1 className="t-left f-medium normal" dangerouslySetInnerHTML={{ __html: subtitle }} />
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
