import React from "react"

const AboutSection = ({ data = {} }) => (
    <div id="about">
        {data.servicesTitle ? (
        <div className="section-content big-text">
            <h2 className="t-left f-large normal dark">{data.servicesTitle}</h2>
            <div className="separator-small"></div>
        </div>
        ) : null}
        <div className="section-content">
            <div className="row">
                <div className="col-md-12">
                    <div className="about-content">
                        <ul className="about-switch no-margin-bottom" data-uk-switcher="{connect:'#about-switch', animation: 'fade'}">
                            {data.servicesFind ? (
                                <li>
                                    <a href="#find">
                                        <span role="img" aria-label="Glass" className="f-expanded">🔍</span>
                                        <p className="title t-left f-normal normal dark">{data.servicesFind}</p>
                                    </a>
                                </li>
                            ) : null}
                            {data.servicesSell ? (
                                <li>
                                    <a href="#sell">
                                        <span role="img" aria-label="Box" className="f-expanded">📦</span>
                                        <p className="title t-left f-normal normal dark">{data.servicesSell}</p>
                                    </a>
                                </li>
                            ) : null}
                            {data.servicesBrokerage ? (
                                <li>
                                    <a href="#brokerage">
                                        <span role="img" aria-label="Handshake" className="f-expanded">🤝</span>
                                        <p className="title t-left f-normal normal dark">{data.servicesBrokerage}</p>
                                    </a>
                                </li>
                            ) : null}
                            {data.servicesMonetize ? (
                                <li>
                                    <a href="#monetize">
                                        <span role="img" aria-label="Graphic" className="f-expanded">📈</span>
                                        <p className="title t-left f-normal normal dark">{data.servicesMonetize}</p>
                                    </a>
                                </li>
                            ) : null}
                            {data.servicesListen ? (
                                <li>
                                    <a href="#listen">
                                        <span role="img" aria-label="Ear" className="f-expanded">👂</span>
                                        <p className="title t-left f-normal normal dark">{data.servicesListen}</p>
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                        <ul id="about-switch" className="uk-switcher">
                            {data.servicesFindTitle ? (
                                <li>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="t-left f-normal normal dark">{data.servicesFindTitle}</h4>
                                            {data.servicesFindText ? <p className="f-small normal t-left mobile-t-center gray2">{data.servicesFindText}</p> : null}
                                        </div>
                                    </div>
                                </li>
                            ) : null}
                            {data.servicesSellTitle ? (
                                <li>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="t-left f-normal normal dark">{data.servicesSellTitle}</h4>
                                            {data.servicesSellText ? <p className="f-small normal t-left mobile-t-center gray2">{data.servicesSellText}</p> : null}
                                        </div>
                                    </div>
                                </li>
                            ) : null}
                            {data.servicesBrokerageTitle ? (
                                <li>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="t-left f-normal normal dark">{data.servicesBrokerageTitle}</h4>
                                            {data.servicesBrokerageText ? <p className="f-small normal t-left mobile-t-center gray2">{data.servicesBrokerageText}</p> : null}
                                        </div>
                                    </div>
                                </li>
                            ) : null}
                            {data.servicesMonetizeTitle ? (
                                <li>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="t-left f-normal normal dark">{data.servicesMonetizeTitle}</h4>
                                            {data.servicesMonetizeText ? <p className="f-small normal t-left mobile-t-center gray2">{data.servicesMonetizeText}</p> : null}
                                        </div>
                                    </div>
                                </li>
                            ) : null}
                            {data.servicesListenTitle ? (
                                <li>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="t-left f-normal normal dark">{data.servicesListenTitle}</h4>
                                            {data.servicesListenText ? <p className="f-small normal t-left mobile-t-center gray2">{data.servicesListenText}</p> : null}
                                        </div>
                                    </div>
                                </li>
                            ) : null}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>		
);

export default AboutSection;
