import React, { useRef, useEffect } from "react"
import { Link, navigate } from "gatsby"

import image from "../images/placeholder.png"

const PropertiesSection = ({ tags, properties, tagsTitle, tagsTitleAlt }) => {
  const tagsToShow =
    tags && tags.edges && tags.edges.length
      ? tags.edges.map(item => item.node.data.NameTrans)
      : []
  const tagsSlug =
    tags && tags.edges && tags.edges.length
      ? tags.edges.map(item => item.node.fields.slugTrans)
      : []

  return (
    <div id="portfolio">
      <div className="section-content-full">
        <ul id="work" className="works-filter-full uk-subnav ultrabold">
          {tagsTitle ? (
            <li className="full-mobile t-left">
              <span
                className="f-normal"
                dangerouslySetInnerHTML={{ __html: tagsTitle }}
              />
            </li>
          ) : null}
          {tagsToShow.map((tagName, i) => (
            <li key={i} data-uk-filter={tagName}>
              <Link
                title={tagsTitleAlt.replace("%s", tagName)}
                to={`/${tagsSlug[i]}`}
              >
                {tagName}
              </Link>
            </li>
          ))}
        </ul>
        <div className="gutter-container" data-uk-grid="{controls: '#work'}">
          {properties && properties.edges && properties.edges.length
            ? properties.edges.map(({ node }, i) => (
                <Property
                  key={i}
                  filter={node.data.Tags.filter(t =>
                    tagsToShow.includes(t.data.NameTrans)
                  )
                    .map(t => t.data.NameTrans)
                    .join(" ")}
                  {...node}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  )
}

export const Property = ({ data, fields, filter, gutter = true }) => {
  const divRef = useRef()

  useEffect(() => {
    if (divRef && divRef.current) {
      window.$(divRef.current).on("click", function() {
        navigate(`/${fields.slugTrans}`)
      })
    }

    return () => {
      window.$(divRef.current).off("click")
    }
  }, [])

  let params = {}
  if (filter) {
    params["data-uk-filter"] = filter
  }

  return (
    <div
      ref={divRef}
      className={`col-sm-6 col-lg-4 work ${
        gutter === false ? "" : "gutter"
      } text-left`}
      {...params}
    >
      <div className="img-responsive position-relative">
        <img
          className="img-responsive"
          src={
            data.FeaturedImage && data.FeaturedImage.childImageSharp
              ? data.FeaturedImage.childImageSharp.resize.src
              : image
          }
          alt={data.TitleTrans}
        />
        {!!data.BannerTrans && (
          <span className="properties-banner f-semi-expanded">
            {data.BannerTrans}
          </span>
        )}
      </div>
      <div className="property-min-height">
        <h3 className="work-title t-left f-semi-expanded normal">
          <Link to={`/${fields.slugTrans}`} title={data.TitleTrans}>
            {data.TitleTrans}
          </Link>
        </h3>
        <p className="work-description t-left f-small">
          {[
            data.BedroomsTrans,
            data.SurfaceTrans ? `${data.SurfaceTrans} m²` : "",
            fields.opportunityTrans,
          ]
            .filter(i => !!i)
            .join(" — ")}
          <br />
          {fields.locationTrans}
        </p>
        <p className="work-price t-left f-normal bold">{data.PriceTrans} €</p>
      </div>
    </div>
  )
}

Property.defaultProps = {
  filter: "",
}

export default PropertiesSection
