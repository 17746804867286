import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/hero-section"
import PropertiesSection from "../components/properties-section"
import CtaSection from "../components/cta-section"
import AboutSection from "../components/about-section"

const monthNames = {
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  es: [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ],
}
const d = new Date()

const IndexPage = ({ data, pageContext }) => {
  return (
    <Layout {...pageContext}>
      <SEO
        lang={pageContext.lang}
        title={data.seoTitleText}
        description={data.seoDescriptionText}
        path={pageContext.pageUrl}
      />
      <HeroSection
        lang={pageContext.lang}
        title={data.homeHeroTitleText}
        subtitle={data.homeHeroSubtitleText}
      />
      <PropertiesSection
        tagsTitle={data.homePropertiesTitleText.replace(
          "%s",
          `${
            monthNames[monthNames[pageContext.lang] ? pageContext.lang : "en"][
              d.getMonth()
            ]
          }`
        )}
        tagsTitleAlt={data.homePropertiesTagTitleText}
        tags={data.tags}
        properties={data.properties}
      />
      <CtaSection
        title={data.ctaSectionTitle}
        link={data.ctaSectionCta}
        linkHref={data.ctaSectionCtaHref}
        linkTitle={data.ctaSectionCtaTitle}
      />
      <AboutSection data={data} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  fragment ServicesFragment on Query {
    servicesTitle: getTranslation(lang: $lang, key: "services_title")
    servicesFind: getTranslation(lang: $lang, key: "services_find")
    servicesFindTitle: getTranslation(lang: $lang, key: "services_find_title")
    servicesFindText: getTranslation(lang: $lang, key: "services_find_text")
    servicesSell: getTranslation(lang: $lang, key: "services_sell")
    servicesSellTitle: getTranslation(lang: $lang, key: "services_sell_title")
    servicesSellText: getTranslation(lang: $lang, key: "services_sell_text")
    servicesBrokerage: getTranslation(lang: $lang, key: "services_brokerage")
    servicesBrokerageTitle: getTranslation(
      lang: $lang
      key: "services_brokerage_title"
    )
    servicesBrokerageText: getTranslation(
      lang: $lang
      key: "services_brokerage_text"
    )
    servicesMonetize: getTranslation(lang: $lang, key: "services_monetize")
    servicesMonetizeTitle: getTranslation(
      lang: $lang
      key: "services_monetize_title"
    )
    servicesMonetizeText: getTranslation(
      lang: $lang
      key: "services_monetize_text"
    )
    servicesListen: getTranslation(lang: $lang, key: "services_listen")
    servicesListenTitle: getTranslation(
      lang: $lang
      key: "services_listen_title"
    )
    servicesListenText: getTranslation(lang: $lang, key: "services_listen_text")
  }

  query IndexPage($lang: String!) {
    seoTitleText: getTranslation(lang: $lang, key: "home_seo_title")
    seoDescriptionText: getTranslation(lang: $lang, key: "home_seo_description")
    homeHeroTitleText: getTranslation(
      lang: $lang
      key: "home_hero_title"
      html: true
      removeWrapper: true
    )
    homeHeroSubtitleText: getTranslation(
      lang: $lang
      key: "home_hero_subtitle"
      html: true
      removeWrapper: true
    )
    homePropertiesTitleText: getTranslation(
      lang: $lang
      key: "home_properties_title"
      html: true
      removeWrapper: true
    )
    homePropertiesTagTitleText: getTranslation(
      lang: $lang
      key: "home_properties_tag_title"
    )
    properties: allAirtable(
      filter: {
        table: { eq: "Properties" }
        data: { Public: { eq: true }, Featured: { gt: 0 } }
      }
      sort: { fields: data___Featured, order: ASC }
    ) {
      edges {
        node {
          fields {
            slugTrans(lang: $lang)
            opportunityTrans(lang: $lang)
            locationTrans(lang: $lang)
          }
          data {
            Location
            TitleTrans(lang: $lang)
            BannerTrans(lang: $lang)
            SurfaceTrans(lang: $lang)
            BedroomsTrans(lang: $lang)
            PriceTrans(lang: $lang)
            Tags {
              recordId
              data {
                NameTrans(lang: $lang)
              }
            }
            FeaturedImage {
              childImageSharp {
                resize(width: 1200, height: 750) {
                  src
                }
              }
            }
          }
        }
      }
    }
    tags: allAirtable(
      filter: { table: { eq: "Tags" }, data: { Public: { eq: true } } }
    ) {
      edges {
        node {
          recordId
          fields {
            slugTrans(lang: $lang)
          }
          data {
            NameTrans(lang: $lang)
          }
        }
      }
    }
    ctaSectionTitle: getTranslation(lang: $lang, key: "cta_section_title")
    ctaSectionCta: getTranslation(lang: $lang, key: "cta_section_cta")
    ctaSectionCtaHref: getTranslation(lang: $lang, key: "cta_section_cta_href")
    ctaSectionCtaTitle: getTranslation(
      lang: $lang
      key: "cta_section_cta_title"
    )
    ...ServicesFragment
  }
`
